.Margin {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 0px;
  }

  .main-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .logout-button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    cursor: pointer;

    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
  }

  .tab-panel {
    padding-left: 40px;
    padding-right: 20px;
    margin-left: 10px;
  }

  .login-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-bottom: -45px; */
  }