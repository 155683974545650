.Margin {
    display: flex;
    justify-content: space-between;
    margin: 50px 0px 0px 0px;
  }

  .tabs-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0px;
  }

  .ant-table-tbody{
    padding: 0 !important;
  }

  .ant-table-tbody > tr > td{
    padding: 0 !important;
  }

  .container-padding {
    padding: 0px !important;
  }

  .ant-tabs-nav {
    margin-bottom: 10px!important;
  }
