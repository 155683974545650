  .register-button {
    padding: 0!important;
    color: #069;
    text-align: start;
  }

  .register-modal-button {
    padding: 0!important;
    color: #069;
    text-align: start;
  }

  .user-name {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    cursor: pointer;
    color: #069;
    cursor: pointer;
    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
    font-family: arial, sans-serif;
    margin-bottom: 0px;
    margin-right: 5px;
  }

.register-card{
  display: flex;
  flex-direction: row;
  justify-content:space-between
}

.register-card-column{
  display: flex;
  flex-direction: column;
}

.register-card-label {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.register-card-input {
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px;
}

.register-card-footer {
  display: flex;
  flex-direction: row;
  justify-content:space-between
}