html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.weekly-chart-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
}

h1,
p {
  font-family: Lato;
}

.instrument-bars-count-div {
  display: flex;
  flex-direction: row;

}

.bars-count {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  max-width: 100px;
  max-height: 32px;
}

.bars-count-label {
  padding-top: 5px;
  margin-right: 5px;
}

.load-button {
  color: #069;
}