.MetricAndChart {
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
}

.RowOfFilters{
    display: flex;
    margin-bottom: 10px;
}