.login-button {
  color: #069;
  margin-right: 5px;
}

.login-modal-button {
  color: #069;
  margin-right: 5px;
}

.user-name {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    cursor: pointer;
    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
    font-family: arial, sans-serif;
    margin-bottom: 0px;
    margin-right: 5px;
  }

  .login-card{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  
  .login-card-column{
    display: flex;
    flex-direction: column;
  }
  
  .login-card-label {
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .login-card-input {
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 1px;
  }
  
  .login-card-footer {
    display: flex;
    flex-direction: row;
    justify-content:space-between
  }