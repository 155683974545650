.red-row {
    background-color: rgb(250, 234, 218);
    ;
}

.green-row {
    background-color: rgb(235, 240, 223);
}

.grey-row {
    background-color: rgb(250, 234, 218);
}

.container-padding {
    padding-top: 0px;
    
}

.save-button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    cursor: pointer;

    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
  }

  
  .ref-button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    cursor: pointer;

    perspective-origin: 0 0;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    transform-origin: 0 0;
  }
