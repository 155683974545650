html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.daily-chart-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 160px);
}

h1,
p {
  font-family: Lato;
}